<template>
    <el-dialog title="卷内档案" append-to-body :close-on-click-modal="false" :fullscreen="true" :visible.sync="visible"
               class="dialogTable" v-if="visible">
        <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm"
                 label-width="100px">
            <el-form-item prop="keyword" label="输入搜索：">
                <el-input v-model.trim="searchForm.keyword" maxlength="50" placeholder="题名/档号"
                          clearable @keyup.enter.native="srhListData(1)"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="srhListData(1)">查询</el-button>
                <el-button icon="el-icon-refresh-right" @click="reset()">重置</el-button>
            </el-form-item>
        </el-form>
        <el-table
                :data="dataList"
                v-loading="loading"
                size="small"
                height="calc(100vh - 240px)"
                @selection-change="selectionChangeHandle"
                class="table" ref="multipleTable" row-key='id'>
            <el-table-column type="selection" :reserve-selection='true' fixed/>
            <el-table-column prop="archivesDataName" label="题名" min-width="120" show-overflow-tooltip/>
            <el-table-column prop="warehousingState" label="状态" show-overflow-tooltip></el-table-column>
            <el-table-column prop="quanzongNumber" label="全宗号" min-width="120"
                             show-overflow-tooltip></el-table-column>
            <el-table-column prop="quanzongName" label="全宗名称" min-width="120"
                             show-overflow-tooltip></el-table-column>
            <el-table-column prop="reference" label="档号" min-width="120"
                             show-overflow-tooltip></el-table-column>
            <el-table-column v-for="item in config" :key="item.archivesBasicId"
                             :prop="item.code"
                             :label="item.forName ? item.forName : item.basicName"
                             v-if="item.dataSelect == 0"
                             :sortable="item.archivesBasicTypeCode == 'date' || item.archivesBasicTypeCode == 'integer' ? 'custom' : false"
                             show-overflow-tooltip min-width="120">
                <template slot="header" slot-scope="scope">
                    <el-tooltip class="item" effect="dark" :content="item.forName ? item.forName : item.basicName"
                                placement="top-start">
                        <span class="omit">{{item.forName ? item.forName : item.basicName}}</span>
                    </el-tooltip>
                </template>
                <template slot-scope="scope">
                    <span v-if="item.archivesBasicTypeCode == 'date'">{{metadataDateFormatValue(item, scope.row.archivesData[item.code])}}</span>
                    <span v-else>{{ scope.row.archivesData[item.code]}}</span>
                </template>
            </el-table-column>
            <el-table-column fixed="right" width="100" label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" v-if="hasPermission('recordation:list:view')"
                               @click="addEdit(scope.row, 'view')">详情
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="searchForm.current"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="searchForm.size"
                :total="total"
                background
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <ListForm ref="listForm"></ListForm>
    </el-dialog>
</template>

<script>
    import ListForm from './listForm'

    export default {
        components: {
            ListForm
        },
        data() {
            return {
                id: '',
                visible: false,
                loading: false,
                searchForm: {
                    keyword: '',
                    archivesBasicDataId: null,
                    current: 1,
                    size: 10,
                    whereList: [],
                    sortArchivesDataElasticsearch: [],
                    fieldList: [],
                    "fieldOrList": [],
                    fieldWhereList: [],
                },
                config: [],
                dataList: [],
                total: 0,
                dataListSelections: [],
                dataFlag: true,
            }
        },
        methods: {
            init(row) {
                this.id = row.id
                this.loading = false
                this.visible = true
                this.dataFlag = true
                this.getInfo(row)
            },
            getInfo(row) {
                this.$axios(this.api.record.getArchivesBasicDataCaseFileByArchivesDataId, {
                    archivesDataId: row.id,
                    basicDataId: row.archivesBasicDataId,
                }, 'get').then(data => {
                    if (data.status) {
                        this.searchForm.archivesBasicDataId = data.data.archivesBasicDataId
                        let {whereList, fieldList, fieldOrList, fieldWhereList} = data.data
                        this.searchForm.whereList = whereList ? whereList : []
                        this.searchForm.fieldList = fieldList ? fieldList : []
                        this.searchForm.fieldOrList = fieldOrList ? fieldOrList : []
                        this.searchForm.fieldWhereList = fieldWhereList ? fieldWhereList : []
                        this.searchForm.whereList.map(item => {
                            this.$set(item, 'key', item.theKey)
                        })
                        this.searchForm.fieldList.map(item => {
                            this.$set(item, 'key', item.theKey)
                        })
                        this.searchForm.fieldOrList.map(item => {
                            this.$set(item, 'key', item.theKey)
                        })
                        this.searchForm.fieldWhereList.map(item => {
                            this.$set(item, 'key', item.theKey)
                        })
                        this.getConfig()
                    } else {
                        this.dataFlag = false
                    }
                })
            },
            // 获取字段
            getConfig() {
                this.$axios(this.api.record.basicdataGetById + '/' + this.searchForm.archivesBasicDataId).then(data => {
                    if (data.status) {
                        this.config = data.data.archivesBasicDataGroup
                        this.$refs.multipleTable.clearSelection()
                        this.srhListData(1)
                    }
                })
            },
            // 获取列表数据
            srhListData(type) {
                if(!this.dataFlag) {
                    return
                }
                this.dataList = []
                if (type == 1) {
                    this.searchForm.current = 1
                }
                this.$axios(this.api.record.queryElasticsearchTemplatePage, this.searchForm, 'post').then((res) => {
                    if (res.status) {
                        this.dataList = res.data.records
                        if (this.dataList.length == 0 && this.searchForm.current > 1) {
                            this.searchForm.current--
                            this.srhListData()
                        }
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },
            // 新增
            addEdit(row, method) {
                this.$refs.listForm.init(row, method, row.archivesBasicDataId, 'memorandumDetails')
            },
            //重置
            reset() {
                this.$refs.searchForm.resetFields();
                this.srhListData(1)
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.srhListData()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.srhListData()
            },
        }
    }
</script>

<style scoped>

</style>
